import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';

const useStyles = makeStyles(() => ({
  filters: {
    marginBottom: '20px',
  },
}));

function GammeFilters({ setGammesId, gammesID, firebase }) {
  const classes = useStyles();
  const [allGammes, setAllGammes] = useState([]);

  const getAllGammes = React.useCallback(async () => {
    const gammesSnapshot = await firebase.db.collection('porsche-gammes')
      .orderBy('order')
      .get();
    if (!gammesSnapshot.empty) {
      const gammesList = [];
      gammesSnapshot.forEach((doc) => gammesList.push({
        name: doc.data().name,
        id: doc.id,
      }));
      setAllGammes(gammesList);
    }
  }, [firebase.db]);

  useEffect(() => {
    getAllGammes();
  }, [getAllGammes]);

  function handleCheck(e, key) {
    const index = gammesID.indexOf(key);
    if (index > -1) {
      setGammesId(gammesID.filter((item) => item !== key));
    } else {
      setGammesId((previous) => ([...previous, key]));
    }
  }
  return (
    <div className={classes.filters}>
      {allGammes
        .map((gamme) => (
          <FormControlLabel
            key={gamme.id}
            control={(
              <Checkbox
                onChange={(e) => handleCheck(e, gamme.id)}
                value={gamme.id}
              />
            )}
            label={gamme.name}
          />
        ))}
    </div>

  );
}

const condition = (authUser) => !!authUser;
export default compose(withFirebase, withAuthorization(condition), withRouter)(GammeFilters);
