import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../../components/Firebase';
import { withAuthorization } from '../../components/Session';
import Chart from './components/Chart';
import EventCard from './components/EventCard';
import useEventList from './Hooks/useEventList';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 15,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

function StatisticPage({ firebase }) {
  const classes = useStyles();
  const events = useEventList(firebase);
  const [selectedEvent, setSelectedEvent] = useState(false);

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper className={classes.paper}>
            <h2> Veuillez choisir un évènement </h2>
            <Autocomplete
              id="event-list"
              options={events}
              size="medium"
              getOptionLabel={(option) => option.name}
              onChange={(_, value) => setSelectedEvent(value)}
              renderInput={(params) => (
                <TextField
                  size="medium"
                  fullWidth
                  {...params}
                  label="Liste des évènements"
                  variant="outlined"
                />
              )}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <EventCard event={selectedEvent} firebase={firebase} />
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper className={classes.paper}>
            <h2>Taux de confirmation </h2>
            <Chart type="confirmation" event={selectedEvent} firebase={firebase} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper className={classes.paper}>
            <h2>Taux de "no show"</h2>
            <Chart type="no-show" event={selectedEvent} firebase={firebase} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper className={classes.paper}>
            <h2>Taux de conversion</h2>
            <Chart type="conversion" event={selectedEvent} firebase={firebase} />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

const condition = (authUser) => !!authUser;

export default compose(withFirebase, withAuthorization(condition), withRouter)(StatisticPage);
