import React, { useContext, useEffect, useState } from 'react';
import { compose } from 'recompose';
import axios from 'axios';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { FirebaseContext } from '../../components/Firebase/context';
import { withAuthorization } from '../../components/Session';
import * as ROLES from '../../constants/roles';
import AgentVendorList from '../../components/AgentVendorList';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  button: {
    marginLeft: theme.spacing(2),
    display: 'flex',
  },
}));

function Transition(props) {
  return <Slide {...props} direction="left" />;
}

function VendorPage() {
  const classes = useStyles();
  const [isAddVendor, setIsAddVendor] = useState(false);
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState('');
  const [transition, setTransition] = useState();
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(false);
  const firebase = useContext(FirebaseContext);

  const getVendors = async () => {
    setLoading(true);
    const querySnapshot = await firebase.vendors();
    const vendorsList = querySnapshot.docs.map((doc) => ({ ...doc.data(), uid: doc.id }));
    setVendors(vendorsList);
    setLoading(false);
  };

  useEffect(() => {
    getVendors();
    return () => { setVendors([]); };
    }, []); // eslint-disable-line

  const handleShowSuccessMsg = (id) => {
    setTransition(() => Transition);
    setOpen(true);
    setUserId(id);
  };

  const handleHideSuccessMsg = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleOpen = () => setIsAddVendor(true);
  const handleClose = () => setIsAddVendor(false);

  const exportVendorTrials = async () => {
    setLoading(true);
    firebase.auth.currentUser.getIdToken(true)
      .then((token) => {
        axios({
          url: `${process.env.REACT_APP_IMPORT_EXPORT_URL}/exportVendorTrials`,
          method: 'GET',
          responseType: 'blob',
          headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob(
            [response.data],
            { type: { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' } },
          ));
          const link = document.createElement('a');
          link.href = url;
          link.download = 'EXPORT_ESSAIS.xlsx';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setLoading(false);
        }).catch(() => {
          setLoading(true);
        });
      })
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <AgentVendorList
              getList={getVendors}
              list={vendors}
              isAdd={isAddVendor}
              handleShowSuccessMsg={handleShowSuccessMsg}
              handleOpen={handleOpen}
              handleClose={handleClose}
              loading={loading}
              type="VENDEUR"
              exportTrials={exportVendorTrials}
            />
          </Paper>
        </Grid>
      </Grid>
      <Snackbar
        TransitionComponent={transition}
        open={open}
        autoHideDuration={3000}
        onClose={handleHideSuccessMsg}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleHideSuccessMsg}
          severity="success"
        >
          Le vendeur
          {' '}
          {userId}
          {' '}
          a bien été ajouté.
        </Alert>
      </Snackbar>
    </Container>
  );
}

const condition = (authUser) => authUser && (authUser.role === ROLES.ADMIN || authUser.role === ROLES.AGENT);

export default compose(withAuthorization(condition))(VendorPage);
