import React, { useContext } from 'react';
import { AuthUserContext } from '../../components/Session';
import SignInPage from '../SignInPage';
import EventsPage from '../EventsPage';
import * as ROLES from '../../constants/roles';

function MainPage() {
  const authUser = useContext(AuthUserContext);
  if (authUser && authUser.role === ROLES.VENDOR) {
    return (
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%,-50%)',
        }}
      >
        <h1>Désolé, votre accès n’est pas autorisé</h1>
      </div>
    );
  }
  if (authUser) {
    return <EventsPage />;
  }
  return <SignInPage />;
}
export default MainPage;
