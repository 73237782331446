import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Formik } from 'formik';
import axios from 'axios';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {
  MenuItem,
  useMediaQuery,
  Button,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  Snackbar,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { Alert } from '@material-ui/lab';
import Form from '../AgentVendorForm';
import { FirebaseContext } from '../Firebase/context';
import { AuthUserContext } from '../Session';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: 'none',
    boxSizing: 'initial',
  },
  margin: {
    margin: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  table: {
    minWidth: 700,
  },
  title: {
    margin: theme.spacing(0, 0, 2, 1),
    fontSize: 36,
    fontWeight: 'bold',
    letterSpacing: 0,
  },
  chip: {
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  submit: {
    margin: theme.spacing(2),
    textTransform: 'none',
    boxSizing: 'initial',
  },
  Fab: {
    '&:first-child': {
      marginLeft: '0px',
    },
    '&:last-child': {
      marginLeft: '15px',
    },
    maxWidth: '35px',
    maxHeight: '30px',
  },
  btnActions: {
    display: 'inline-flex',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: '40px',
  },
  modalTitle: {
    fontSize: '19px',
    fontWeight: 'bold',
    letterSpacing: 0,
    textAlign: 'center',
    textTransform: 'unset',
  },
  formControl: {
    marginTop: 10,
    '& Label': {
      background: '#fff',
      padding: '0 5px',
    },
  },
}));

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-exists';
const ERROR_MSG_ACCOUNT_EXISTS = "L'identifiant choisi existe déjà. Veuillez en choisir un autre.";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function AgentVendorList({
  getList,
  list,
  loading,
  isAdd,
  handleShowSuccessMsg,
  handleOpen: openForm,
  handleClose: closeForm,
  type,
  exportTrials,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('false'));
  const firebase = useContext(FirebaseContext);
  const [selectedUser, setSelectedUser] = useState({});
  const [alert, setAlert] = useState({
    isOpen: false,
    msg: '',
    severity: 'success',
  });
  const [updateUserError, setUpdateUserError] = useState({});
  const [isUpdateModal, setIsUpdateModal] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [allCenter, setAllCenter] = useState([]);
  const [templates, setTemplates] = useState([]);
  const authUser = useContext(AuthUserContext);

  useEffect(() => {
    firebase.porscheCenters().onSnapshot((snapshot) => {
      const porscheCenters = [];
      snapshot.forEach((doc) => {
        const docData = { ...doc.data() };
        porscheCenters.push(docData.porscheCenterName);
      });
      setAllCenter(porscheCenters);
    });
  }, [firebase]);

  useEffect(() => {
    const subscriber = firebase.db.collection('templates').onSnapshot((snapshot) => {
      if (!snapshot.empty) {
        const data = snapshot.docs.map((elem) => ({
          id: elem.id,
          ...elem.data(),
        }));
        setTemplates(data);
      }
    });

    return () => {
      subscriber();
    };
  }, [firebase]);

  const UserRef = firebase.db.collection('users');

  const handleDelete = (agent) => {
    setIsDeleteModal(true);
    setSelectedUser(agent);
  };
  const handleEdit = (agent) => {
    setIsUpdateModal(true);
    setSelectedUser(agent);
  };
  const handleClose = () => {
    setIsDeleteModal(false);
    setIsUpdateModal(false);
  };

  const deleteUser = async (id) => {
    axios({
      url: `${process.env.REACT_APP_IMPORT_EXPORT_URL}/deleteUser/${id}`,
      method: 'DELETE',
      headers: { Authorization: `Bearer ${authUser.token}` },
    }).then(() => {
      getList();
      setAlert({
        isOpen: true,
        msg: 'L’utilisateur a bien été supprimé !',
      });
      setIsDeleteModal(false);
    });
  };

  const updateUserCallBack = () => {
    getList();
    setAlert({
      isOpen: true,
      msg: 'Vos modifications ont bien été prises en compte !',
    });
    setIsUpdateModal(false);
  };

  const updateEmail = async ({
    id, email, porscheCenters, idTemplate, idTemplateEvent,
  }) => {
    firebase.auth.currentUser.getIdToken(true).then((token) => {
      axios({
        url: `${process.env.REACT_APP_IMPORT_EXPORT_URL}/updateUser/${id}`,
        method: 'POST',
        data: {
          email,
          id,
        },
        headers: { Authorization: `Bearer ${token}` },
      })
        .then(() => {
          UserRef.doc(selectedUser.uid)
            .set(
              {
                email,
                porscheCenters,
                idTemplate,
                idTemplateEvent,
              },
              { merge: true },
            )
            .then(() => {
              updateUserCallBack();
            });
        })
        .catch((error) => {
          if (
            error
            && error.response
            && error.response.data
            && error.response.data.code === ERROR_CODE_ACCOUNT_EXISTS
          ) {
            setUpdateUserError({ ...error, message: ERROR_MSG_ACCOUNT_EXISTS });
          }
        });
    });
  };

  const modifSubmit = async (values) => {
    try {
      if (selectedUser.email === values.email) {
        UserRef.doc(selectedUser.uid)
          .set(
            {
              idTemplate: values.idTemplate,
              idTemplateEvent: values.idTemplateEvent,
              porscheCenters: values.porscheCenters,
            },
            { merge: true },
          )
          .then(() => updateUserCallBack());
      } else {
        await updateEmail({
          id: selectedUser.uid,
          email: values.email,
          porscheCenters: values.porscheCenters,
          idTemplate: values.idTemplate,
          idTemplateEvent: values.idTemplateEvent,
        });
      }
    } catch (error) {
      setAlert({
        isOpen: true,
        msg: 'Erreur survenue',
        severity: 'error',
      });
    }
  };

  const validateValues = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = 'Ce champ est obligatoire';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = 'Veuillez saisir un identifiant valide (e-mail).';
    } else if (values.email.length < 6) {
      errors.email = 'Veuillez saisir un identifiant valide (e-mail).';
    } else if (values.email.length > 100) {
      errors.email = 'Veuillez saisir un identifiant valide (e-mail).';
    }
    if (!values.porscheCenters) {
      errors.porscheCenters = 'Veuillez sélectionner au moins un centre Porsche.';
    } else if (!values.porscheCenters.length) {
      errors.porscheCenters = 'Veuillez sélectionner au moins un centre Porsche.';
    }
    return errors;
  };

  function filterByCenter(user) {
    return authUser.role === 'AGENT' ? authUser.porscheCenters.some((center) => user.porscheCenters.includes(center)) : true;
  }

  if (loading) return <LinearProgress variant="query" color="secondary" />;

  return (
    <div>
      <Typography color="primary" className={classes.title}>
        Liste des
        {' '}
        {type === 'AGENT' ? 'agents' : 'vendeurs'}
      </Typography>

      {
        isAdd ? (
          <Grid
            item
            xs={8}
            style={{
              flexBasis: '80%',
              maxWidth: '80%',
            }}
          >
            <Paper
              style={{
                padding: '10px 20px',
                width: '100%',
              }}
            >
              <Form
                onClose={closeForm}
                handleShowSuccessMsg={handleShowSuccessMsg}
                getList={() => getList()}
                type={type}
                templates={templates}
              />
            </Paper>
          </Grid>
        ) : (
          <div
            className={classes.btnActions}
            style={{
              visibility: !isAdd ? 'visible' : 'hidden',
              height: !isAdd ? 'auto' : '0',
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={openForm}
            >
              Ajouter un
              {' '}
              {type === 'AGENT' ? 'agent' : 'vendeur'}
            </Button>
            {type === 'VENDEUR' && (
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                onClick={exportTrials}
              >
                Export suivis essais
              </Button>
            )}
          </div>
        )
      }

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Identifiant</StyledTableCell>
              <StyledTableCell align="left">Rôle</StyledTableCell>
              <StyledTableCell align="left">Centres Porsche</StyledTableCell>
              <StyledTableCell align="left">Template</StyledTableCell>
              <StyledTableCell align="left">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.filter(filterByCenter).map((user) => (
              <StyledTableRow key={user.uid}>
                <StyledTableCell component="th" scope="row">
                  {user.email}
                </StyledTableCell>
                <StyledTableCell align="left">{user.role}</StyledTableCell>
                <StyledTableCell align="left" className={classes.chip}>
                  {user.porscheCenters
                    && user.porscheCenters.map((item) => (
                      <Chip label={item} variant="outlined" key={item} />
                    ))}
                </StyledTableCell>
                <StyledTableCell align="left" className={classes.chip}>
                  {user.idTemplate && <Chip label={templates.find((template) => template.id === user.idTemplate).currentName} variant="outlined" />}
                  {user.idTemplateEvent && <Chip label={templates.find((template) => template.id === user.idTemplateEvent).currentName} variant="outlined" />}
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      align: 'left',
                      width: '100%',
                      minWidth: '90px',
                    }}
                  >
                    <Fab
                      className={classes.Fab}
                      color="primary"
                      aria-label="view"
                      onClick={() => handleDelete(user)}
                    >
                      <DeleteIcon />
                    </Fab>
                    <Fab
                      className={classes.Fab}
                      onClick={() => handleEdit(user)}
                      color="default"
                    >
                      <EditIcon />
                    </Fab>
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Snackbar
        open={alert.isOpen}
        autoHideDuration={3000}
        onClose={() => setAlert({ isOpen: false, msg: '' })}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setAlert({
            isOpen: false,
            msg: '',
          })}
          severity={alert?.severity || 'success'}
        >
          {alert.msg}
        </Alert>
      </Snackbar>
      <Dialog
        fullScreen={fullScreen}
        open={isDeleteModal}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div style={{ padding: '10px 24px' }}>
          <DialogTitle>
            <span style={{ textTransform: 'none' }}>
              Suppression d'un utilisateur
            </span>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText color="primary">
              Êtes-vous sûr de vouloir supprimer cet utilisateur?
            </DialogContentText>
          </DialogContent>
          <DialogActions
            style={{
              paddingBottom: '20px',
              alignItems: 'center',
              justifyContent: 'space-evenly',
            }}
          >
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={handleClose}
            >
              Annuler
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              onClick={async () => {
                await deleteUser(selectedUser.uid);
              }}
            >
              <span style={{ margin: '0 10px' }}>
                &gt;
              </span>
              Confirmer
            </Button>
          </DialogActions>
        </div>
      </Dialog>

      <Dialog
        open={isUpdateModal}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <Formik
          initialValues={{
            email: selectedUser.email,
            porscheCenters: selectedUser.porscheCenters,
            idTemplate: selectedUser.idTemplate,
            idTemplateEvent: selectedUser.idTemplateEvent,
          }}
          validate={validateValues}
          onSubmit={({
            email, porscheCenters, idTemplate, idTemplateEvent,
          }) => modifSubmit(
            {
              email,
              porscheCenters,
              idTemplate,
              idTemplateEvent,
            },
          )}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            setFieldValue,
            touched,
          }) => (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(values);
              }}
            >
              <DialogTitle style={{ textAlign: 'center' }}>
                <span style={{ textTransform: 'none' }}>
                  Modifier l'utilisateur
                </span>
                <IconButton
                  aria-label="close"
                  className={classes.closeButton}
                  onClick={handleClose}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Veuillez modifier les informations souhaitées
                </DialogContentText>
                <TextField
                  type="email"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={handleChange}
                  value={values.email}
                  label="Adresse e-mail"
                  style={{ marginBottom: '5px', width: '500px' }}
                />
                {errors.email && (
                  <div style={{ color: '#d5001c', padding: '4px 18px' }}>
                    {errors.email}
                  </div>
                )}
                {touched.email && Boolean(errors.email)}
                {updateUserError && (
                  <div style={{ color: '#d5001c' }}>{updateUserError.message}</div>
                )}
                <Autocomplete
                  multiple
                  id="size-small-filled-multi"
                  size="medium"
                  style={{ marginTop: '25px' }}
                  onChange={(_, value) => setFieldValue('porscheCenters', value)}
                  value={values.porscheCenters}
                  options={allCenter}
                  disableCloseOnSelect
                  renderOption={(center, { selected }) => (
                    <>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {center}
                    </>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Centre(s) Porsche"
                      placeholder="Sélectionner un centre Porsche !"
                      style={{ width: 500 }}
                      fullWidth
                      helperText={
                        errors.porscheCenters && (
                          <span
                            style={{
                              color: '#d5001c',
                              fontSize: '14px',
                              margin: '8px 0px 0px',
                            }}
                          >
                            {errors.porscheCenters}
                          </span>
                        )
                      }
                      error={
                        touched.porscheCenters && Boolean(errors.porscheCenters)
                      }
                    />
                  )}
                />
                <FormControl variant="outlined" style={{ width: '100%' }} className={classes.formControl}>
                  <InputLabel htmlFor="outlined-template-native-simple">Sélectionner un template</InputLabel>
                  <Select
                    value={values.idTemplate}
                    onChange={handleChange}
                    inputProps={{
                      name: 'idTemplate',
                      id: 'outlined-template-native-simple',
                    }}
                  >
                    <MenuItem aria-label="None" value="" style={{ padding: '20px' }} />
                    {templates.map((elem) => (
                      <MenuItem key={elem.id} value={elem.id}>
                        {elem.currentName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {selectedUser.role === 'AGENT'
                  && (
                    <FormControl variant="outlined" style={{ width: '100%' }} className={classes.formControl}>
                      <InputLabel htmlFor="outlined-template-event-native-simple">Sélectionner un template d'événement</InputLabel>
                      <Select
                        value={values.idTemplateEvent}
                        onChange={handleChange}
                        inputProps={{
                          name: 'idTemplateEvent',
                          id: 'outlined-template-event-native-simple',
                        }}
                      >
                        <MenuItem aria-label="None" value="" style={{ padding: '20px' }} />
                        {templates.map((elem) => (
                          <MenuItem key={elem.id} value={elem.id}>
                            {elem.currentName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}

              </DialogContent>
              <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                  className={classes.submit}
                  variant="contained"
                  color="primary"
                  onClick={handleClose}
                >
                  Annuler
                </Button>
                <Button
                  type="submit"
                  className={classes.submit}
                  variant="contained"
                  color="secondary"
                >
                  <span style={{ paddingRight: '10px' }}>
                    &gt;
                  </span>
                  Enregistrer
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
}

export default AgentVendorList;
