import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { withAuthentication } from './components/Session';
import * as ROUTES from './constants/routes';
import AddEventPage from './pages/AddEventPage';
import AgentsPages from './pages/AgentPage';
import EventDetailPage from './pages/EventDetailPage';
import EventsPage from './pages/EventsPage';
import PersonsPage from './pages/PersonsPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import StatisticPage from './pages/StatisticPage';
import SignInPage from './pages/SignInPage';
import Layout from './shared/Layout';
import UpdateEventPage from './pages/UpdateEventPage';
import MainPage from './pages/MainPage';
import CatalogPage from './pages/CatalogPage';
import AddGammePage from './pages/AddGammePage';
import EditGammePage from './pages/EditGammePage';
import AddModelPage from './pages/AddModelPage';
import EditModelPage from './pages/EditModelPage';
import AddVersionPage from './pages/AddVersionPage';
import EditVersionPage from './pages/EditVersionPage';
import CentersPorsche from './pages/PorscheCenterPage';
import VendorPage from './pages/VendorPage';
import DischargesPage from './pages/DischargesPage';

function App() {
  return (
    <Router>
      <Layout>
        <div>
          <Route exact path={ROUTES.MAIN} component={MainPage} />
          <Route path={ROUTES.SIGN_IN} component={SignInPage} />
          <Route path={ROUTES.AGENT} component={AgentsPages} />
          <Route path={ROUTES.EVENTS} component={EventsPage} />
          <Route path={ROUTES.EVENT_DETAIL} component={EventDetailPage} />
          <Route path={ROUTES.ADD_EVENT} component={AddEventPage} />
          <Route path={ROUTES.UPDATE_EVENT} component={UpdateEventPage} />
          <Route path={ROUTES.PERSONS} component={PersonsPage} />
          <Route path={ROUTES.RESET_PASSWORD} component={ResetPasswordPage} />
          <Route path={ROUTES.STAT} component={StatisticPage} />
          <Route path={ROUTES.CATALOG} component={CatalogPage} />
          <Route path={ROUTES.ADDGAMME} component={AddGammePage} />
          <Route path={ROUTES.UPDATEGAMME} component={EditGammePage} />
          <Route path={ROUTES.ADD_MODEL} component={AddModelPage} />
          <Route path={ROUTES.UPDATE_MODEL} component={EditModelPage} />
          <Route path={ROUTES.ADD_VERSION} component={AddVersionPage} />
          <Route path={ROUTES.UPDATE_VERSION} component={EditVersionPage} />
          <Route path={ROUTES.CENTERS} component={CentersPorsche} />
          <Route path={ROUTES.VENDOR} component={VendorPage} />
          <Route path={ROUTES.DISCHARGES} component={DischargesPage} />
        </div>
      </Layout>
    </Router>
  );
}

export default withAuthentication(App);
