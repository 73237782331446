import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Formik } from 'formik';
import Container from '@material-ui/core/Container';
import { withFirebase } from '../../components/Firebase';
import { withAuthorization } from '../../components/Session';
import ModelForm from '../../components/ModelForm';
import calculOrderModel from '../../helpers/calculOrderModel';

function AddModelForm({ firebase, history }) {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values) => {
    setIsLoading(true);
    const order = await calculOrderModel(firebase, values.gammeId);

    await firebase.db
      .collection('porsche-models')
      .add({ name: values.modelName, gamme_id: values.gammeId, order })
      .then(() => {
        setIsLoading(false);
        history.push({
          pathname: '/catalog',
          state: {
            isModel: true,
          },
        });
      }) // eslint-disable-next-line no-console
      .catch((e) => console.error('Error when adding new model', e));
  };
  const validateValues = (values) => {
    const errors = {};
    if (!values.modelName.length || values.modelName?.trim().length === 0) {
      errors.modelName = 'Veuillez renseigner un nom du modèle';
    }
    if (!values.gammeId.length || values.gammeId?.trim().length === 0) {
      errors.gammeId = 'Veuillez selectionner une gamme';
    }
    return errors;
  };
  return (
    <Formik
      validateOnChange={false}
      initialValues={{
        modelName: '',
        gammeId: '',
      }}
      validate={validateValues}
      onSubmit={({ modelName, gammeId }, { setSubmitting }) => handleSubmit({ modelName, gammeId }, { setSubmitting })}
    >
      {(formik) => (
        <Container component="main">
          <ModelForm
            formik={formik}
            isLoading={isLoading}
            firebase={firebase}
            handleSubmit={handleSubmit}
            mutation="ADD"
          />
        </Container>
      )}
    </Formik>
  );
}

const condition = (authUser) => !!authUser;

const AddModelFormContainer = compose(
  withRouter,
  withFirebase,
  withAuthorization(condition),
)(AddModelForm);

AddModelForm.propTypes = {
  firebase: PropTypes.shape({
    db: PropTypes.object,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default AddModelFormContainer;
