export const MAIN = '/';
export const SIGN_IN = '/signin/:resetPassword?';
export const AGENT = '/agents';
export const EVENTS = '/events';
export const ACCOUNT = '/account';
export const PERSONS = '/persons';
export const RESET_PASSWORD = '/resetPassword';
export const EVENT_DETAIL = '/event/:id';
export const ADD_EVENT = '/add-event';
export const UPDATE_EVENT = '/update-event/:id';
export const STAT = '/stat';
export const CATALOG = '/catalog';
export const ADDGAMME = '/add-gamme';
export const UPDATEGAMME = '/update-gamme/';
export const ADD_MODEL = '/add-model';
export const UPDATE_MODEL = '/update-model';
export const ADD_VERSION = '/add-version';
export const UPDATE_VERSION = '/update-version';
export const CENTERS = '/centers';
export const VENDOR = '/vendors';
export const DISCHARGES = '/discharges-templates';
