import React, { useEffect, useState } from 'react';
import { Form } from 'formik';
import {
  InputLabel,
  FormHelperText,
  FormControl,
  TextField,
  MenuItem,
  Select,
  Button,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '10%',
    marginBottom: '10%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    '& > div': {
      width: '100%',
    },
  },
  form: {
    width: '100%',
    maxWidth: '950px',
  },

  input: {
    width: '409px',
  },
  formControl: {
    minWidth: 409,
    marginTop: 10,
    '& Label': {
      background: '#fff',
      padding: '0 5px',
    },
  },

  selectStyle: {
    background: '#fff',
  },

  button: {
    fontSize: '16px',
    letterSpacing: '0',
    lineHeight: '23px',
    textTransform: 'none',
    marginRight: '30px',
  },
  row: {
    display: 'inline-flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  submitButton: {
    fontSize: '16px',
    letterSpacing: '0',
    lineHeight: '23px',
    float: 'right',
  },
  inputTextContainer: {
    paddingTop: '10px',
  },
}));

function ModelForm({
  formik, isLoading, firebase, handleSubmit, mutation,
}) {
  const classes = useStyles();
  const [gammeList, setGammeList] = useState([]);

  useEffect(() => {
    const asyncFetch = async () => {
      const getGammes = await firebase.db.collection('porsche-gammes').get();
      const gammes = [];

      getGammes.docs.map(async (doc) => {
        const gammeData = doc.data();
        gammes.push({ id: doc.id, gamme: gammeData.name, orderGamme: gammeData.order });
      });
      setGammeList(gammes);
    };

    asyncFetch();
  }, [firebase.db]);

  const handleAdd = async () => {
    try {
      const docRef = await firebase.db
        .collection('porsche-models')
        .get();
      if (
        docRef.docs.length > 0
        && (docRef.docs.findIndex((model) => model.data().name.toLowerCase() === formik.values.modelName.toLowerCase()) !== -1)
      ) {
        formik.setFieldError('modelName', 'Ce modèle existe déjà');
      } else {
        handleSubmit(formik.values);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error getting document:', error);
    }
  };

  const isDisabled = isLoading || !formik.values.modelName || !formik.values.gammeId;

  return (
    <Form className={classes.form}>
      <h1>Catalogue</h1>
      <div className={classes.container}>
        <div>
          <div>
            <h2>{mutation === 'ADD' ? 'Création d\'un modèle' : formik.values.modelName}</h2>
          </div>
          <div className={classes.row}>

            <TextField
              name="modelName"
              error={formik.errors.modelName && formik.touched.modelName}
              variant="outlined"
              type="text"
              margin="normal"
              fullWidth
              helperText={
                formik.errors.modelName && formik.touched.modelName ? formik.errors.modelName : ''
              }
              label="Nom du modèle"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.modelName}
              className={classes.input}
            />
            <FormControl
              variant="outlined"
              id="demo-simple-select-outlined-label"
              className={classes.formControl}
            >
              <InputLabel id="gamme" error={formik.errors.gammeId && formik.touched.gammeId}>
                Gamme
              </InputLabel>
              <Select
                labelId="gamme"
                id="gammeId"
                variant="outlined"
                error={formik.errors.gammeId && formik.touched.gammeId}
                name="gammeId"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label="gamme"
                value={formik.values.gammeId}
              >
                {gammeList?.map((gamme) => (
                  <MenuItem key={gamme.id} value={gamme.id}>
                    {gamme.gamme}
                  </MenuItem>
                ))}
              </Select>

              {formik.errors.gammeId && formik.touched.gammeId ? (
                <FormHelperText error={formik.errors.gammeId && formik.touched.gammeId}>
                  {formik.errors.gammeId}
                </FormHelperText>
              ) : (
                ''
              )}
            </FormControl>

          </div>
        </div>
      </div>
      <Button
        type="button"
        variant="contained"
        color="secondary"
        className={classes.submitButton}
        disabled={isDisabled}
        onClick={handleAdd}
      >
        Enregistrer
      </Button>
    </Form>
  );
}

export default ModelForm;
