import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../../components/Firebase';

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Veuillez saisir un Porsche ID valide';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Veuillez saisir un Porsche ID valide';
  }
  return errors;
};

function ResetPasswordPage() {
  return <ResetPasswordForm />;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function ResetPasswordFormBase(props) {
  const classes = useStyles();
  const [error, setError] = useState('');
  const [info, setInfo] = useState('');

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validate,
    onSubmit: ({ email }) => {
      props.firebase.doResetPassword(email, {
        url: `${process.env.REACT_APP_EMAIL_REDIRECT}/signin/${email}`,
        handleCodeInApp: false,
      })
        .then(() => {
          setInfo('Un email vous a été envoyé !');
        })
        .catch(() => {
          setError('La réinitialisation du mot de passe n\'a pas fonctionné !');
        });
    },
  });

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Mot de passe oublié ?
        </Typography>
        <form
          className={classes.form}
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Identifiant (adresse e-mail)"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={formik.handleChange}
            value={formik.values.email}
            helperText={formik.touched.email ? formik.errors.email : ''}
            error={formik.touched.email && Boolean(formik.errors.email)}
          />
          {error && <Typography color="error">{error}</Typography>}
          {info && <Typography color="primary">{info}</Typography>}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
          >
            Réinitialiser le mot de passe
          </Button>
        </form>
      </div>
    </Container>
  );
}

const ResetPasswordForm = compose(withRouter, withFirebase)(ResetPasswordFormBase);

export default ResetPasswordPage;
