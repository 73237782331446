import Skeleton from '@material-ui/lab/Skeleton';
import React, { memo, useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import useGuestStatInfo from '../../Hooks/useGuestStatInfo';

const defaultData = {
  labels: ['loading ...', 'loading ...'],
  datasets: [
    {
      data: [50, 100],
      backgroundColor: ['#D5001C', '#191F22'],
      hoverBackgroundColor: ['#e53b51', '#2b2b2b'],
    },
  ],
};

export default memo(({ event, firebase, type }) => {
  const [data, setData] = useState(defaultData);
  const { guestsData } = useGuestStatInfo(event, firebase);

  useEffect(() => {
    if (type === 'confirmation') {
      const confirmed = Math.floor((guestsData.totalConfirmedGuest / guestsData.totalGuests) * 100);
      setData({
        labels: ['Invités confirmés', 'Invités non confirmés'],
        datasets: [
          {
            data: [confirmed, 100 - confirmed],
            backgroundColor: ['#D5001C', '#191F22'],
            hoverBackgroundColor: ['#e53b51', '#2b2b2b'],
          },
        ],
      });
    }
    if (type === 'no-show') {
      const confirmedNotPresent = Math.floor(
        (guestsData.totalNotPresentConfirmedGuest / guestsData.totalConfirmedGuest) * 100,
      );
      setData({
        labels: ['Invités confirmés non présents', 'Invités confirmés présents'],
        datasets: [
          {
            data: [confirmedNotPresent, 100 - confirmedNotPresent],
            backgroundColor: ['#D5001C', '#191F22'],
            hoverBackgroundColor: ['#e53b51', '#2b2b2b'],
          },
        ],
      });
    }
    if (type === 'conversion') {
      const leads = Math.floor((guestsData.totalLead / guestsData.totalPresentGuests) * 100);
      setData({
        labels: ['Total des leads', 'Participants sans lead'],
        datasets: [
          {
            data: [leads, 100 - leads],
            backgroundColor: ['#D5001C', '#191F22'],
            hoverBackgroundColor: ['#e53b51', '#2b2b2b'],
          },
        ],
      });
    }
  }, [guestsData, type]);
  if (!event) {
    return <Skeleton variant="rect" height={250} />;
  }
  return (
    <Doughnut
      data={data}
      options={{
        tooltips: {
          callbacks: {
            label(tooltipItem, dataDoughnut) {
              const dataset = dataDoughnut.datasets[tooltipItem.datasetIndex];
              const stringLabel = dataDoughnut.labels[tooltipItem.index];
              const percent = dataset.data[tooltipItem.index];
              return ` ${stringLabel}: ${percent}%`;
            },
          },
        },
      }}
    />
  );
});
