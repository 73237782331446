import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Formik } from 'formik';
import { LinearProgress } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { withFirebase } from '../../components/Firebase';
import { withAuthorization } from '../../components/Session';
import GammeForm from '../../components/GammeForm';

function EditGammeForm({ firebase, history }) {
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    const { state } = history.location;
    setInitialValues(state);
  }, [initialValues, history.location]);

  const updateDocument = async (data) => {
    await firebase.db.collection('porsche-gammes').doc(initialValues.key).set({
      ...data,
    }, { merge: true })
      .then(() => {
        setIsLoading(false);
        history.push({
          pathname: '/catalog',
          state: {
            isGamme: true,
          },
        });
      })
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    if (values.fileUrl === initialValues.gammeURL) {
      updateDocument({ name: values.gammeName });
      return;
    }

    const storageRef = firebase.storage.ref(`gammes/${initialValues.key}`);
    if (initialValues.gammeURL !== undefined) {
      storageRef.delete()
        .then(async () => {
          const blob = await fetch(values.fileUrl).then((r) => r.blob());
          storageRef.put(blob).then(async (snapshot) => {
            const url = await snapshot.ref.getDownloadURL();
            updateDocument({ url, name: values.gammeName });
          })
        })
    } else {
      //
      const blob = await fetch(values.fileUrl).then((r) => r.blob());
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      storageRef.put(blob).then(async (snapshot) => {
        const url = await snapshot.ref.getDownloadURL();
        updateDocument({ url, name: values.gammeName });
      });
      //
    }
  };

  const validateValues = (values) => {
    const errors = {};
    if (!values.gammeName.length || values.gammeName?.trim().length === 0) {
      errors.gammeName = 'Veuillez renseigner un nom de gamme';
    }
    return errors;
  };

  if (!initialValues) return <LinearProgress variant="query" color="secondary" />;

  return (
    <Formik
      validateOnChange={false}
      initialValues={{
        gammeName: initialValues.gammeName,
        fileUrl: initialValues.gammeURL === undefined ? '' : initialValues.gammeURL,
      }}
      validate={validateValues}
      onSubmit={({
        gammeName, fileUrl,
      }, { setSubmitting }) => handleSubmit({
        gammeName, fileUrl,
      }, { setSubmitting })}
    >
      {(formik) => (
        <Container component="main">
          <GammeForm
            formik={formik}
            isLoading={isLoading}
            firebase={firebase}
            handleSubmit={handleSubmit}
            mutation="EDIT"
            initialValues={initialValues}
          />
        </Container>
      )}
    </Formik>
  );
}

const condition = (authUser) => !!authUser;

const EditGammeFormContainer = compose(
  withRouter,
  withFirebase,
  withAuthorization(condition),
)(EditGammeForm);

EditGammeForm.propTypes = {
  firebase: PropTypes.shape({
    db: PropTypes.object,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default EditGammeFormContainer;
