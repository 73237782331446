import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Skeleton from '@material-ui/lab/Skeleton';
import React, { memo } from 'react';
import useGuestStatInfo from '../../Hooks/useGuestStatInfo';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  table: {
    minWidth: 350,
  },
}));

export default memo(({ event, firebase }) => {
  const classes = useStyles();
  const { guestsData } = useGuestStatInfo(event, firebase);
  if (!event) {
    return (
      <Paper className={classes.paper}>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((key) => (
          <Skeleton animation="wave" key={key} />
        ))}
      </Paper>
    );
  }

  return (
    <Paper className={classes.paper}>
      <EventInfoTable event={event} guestsData={guestsData} />
    </Paper>
  );
});

function EventInfoTable({ event = {}, guestsData = {} }) {
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              Nom de l'évènement
            </TableCell>
            <TableCell component="th" scope="row">
              {event.name ? event.name : <Skeleton animation="wave" />}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Nombre total d'invités
            </TableCell>
            <TableCell component="th" scope="row">
              {guestsData.totalGuests || guestsData.totalGuests === 0 ? (
                `${guestsData.totalGuests} invités`
              ) : (
                <Skeleton animation="wave" />
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Nombre total d'invités confirmés
            </TableCell>
            <TableCell component="th" scope="row">
              {guestsData.totalConfirmedGuest || guestsData.totalConfirmedGuest === 0 ? (
                `${guestsData.totalConfirmedGuest} invités confirmés`
              ) : (
                <Skeleton animation="wave" />
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Nombre total de présents
            </TableCell>
            <TableCell component="th" scope="row">
              {guestsData.totalPresentGuests || guestsData.totalPresentGuests === 0 ? (
                `${guestsData.totalPresentGuests} participants`
              ) : (
                <Skeleton animation="wave" />
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Nombre total de leads assistés
            </TableCell>
            <TableCell component="th" scope="row">
              {guestsData.totalLead || guestsData.totalLead === 0 ? (
                `${guestsData.totalLead} leads`
              ) : (
                <Skeleton animation="wave" />
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Nombre total de leads totem
            </TableCell>
            <TableCell component="th" scope="row">
              {guestsData.totalProspect || guestsData.totalProspect === 0 ? (
                `${guestsData.totalProspect} leads`
              ) : (
                <Skeleton animation="wave" />
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
