import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import React from 'react';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function () {
  return (
    <footer>
      <Box pt={4}>
        <Copyright />
      </Box>
    </footer>
  );
}
