import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, Link, useMediaQuery,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../../../components/Firebase';
import timestampToDate from '../../../utils/timestampToDate';
import StyledTableCell from './StyledTableCell';
import StyledTableRow from './StyledTableRow';

const useStyles = makeStyles(() => ({
  Fab: {
    width: '35px',
    height: '30px',
    marginLeft: '5px',
    marginRight: '5px',
  },
}));

function TableRowEvent({
  event, history, status, firebase, asyncFetch, role,
}) {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const EventRef = firebase.db.collection('events');

  async function deleteEvent(eventId) {
    EventRef.doc(eventId).delete().then(async () => {
      await asyncFetch();
    });
  }

  return (
    <StyledTableRow key={event.id}>
      <StyledTableCell align="center">
        <Link href="#" onClick={() => history.push(`event/${event.id}`)} color="inherit">
          {event.name}
        </Link>
      </StyledTableCell>
      <StyledTableCell style={{ align: 'Left', textAlign: 'center' }}>{event.campagneCode}</StyledTableCell>
      <StyledTableCell style={{ align: 'Left', textAlign: 'center' }}>{status}</StyledTableCell>
      <StyledTableCell style={{ align: 'Left', textAlign: 'center' }}>{timestampToDate(event.creationDate)}</StyledTableCell>
      <StyledTableCell style={{ align: 'Left', textAlign: 'center' }}>{timestampToDate(event.startDate)}</StyledTableCell>
      <StyledTableCell style={{ align: 'Left', textAlign: 'center' }}>{timestampToDate(event.endDate)}</StyledTableCell>
      <StyledTableCell style={{ align: 'Left', textAlign: 'center' }}>{event.size}</StyledTableCell>
      <StyledTableCell style={{ align: 'Left', textAlign: 'center' }}>
        <div style={{ width: '100%', display: 'inline-flex' }}>
          <Fab
            className={classes.Fab}
            color="secondary"
            onClick={() => history.push(`event/${event.id}`)}
          >
            <VisibilityIcon />
          </Fab>
          <Fab
            className={classes.Fab}
            color="primary"
            aria-label="view"
            disabled={!event.existed && role !== 'ADMIN'}
          >
            <DeleteIcon
              onClick={handleClickOpen}
            />
            <Dialog
              fullScreen={fullScreen}
              open={open}
              onClose={handleClose}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle>
                <span className={classes.title}>
                  Confirmation de suppression
                </span>
              </DialogTitle>
              <DialogContent>
                <DialogContentText color="primary">
                  Souhaitez-vous vraiment supprimer l'évènement
                  {' '}
                  {`${event.name}`}
                  {' '}
                  ?
                </DialogContentText>

              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} className={classes.Button}>
                  Annuler
                </Button>
                <Button
                  className={classes.Button}
                  onClick={async () => {
                    await deleteEvent(event.id);
                    handleClose();
                  }}
                >
                  Supprimer
                </Button>
              </DialogActions>
            </Dialog>
          </Fab>
          <Fab
            className={classes.Fab}
            color="default"
            onClick={() => history.push(`update-event/${event.id}`)}
            disabled={!event.existed && role !== 'ADMIN'}
          >
            <EditIcon className={classes.Edit} />
          </Fab>
        </div>
      </StyledTableCell>
    </StyledTableRow>
  );
}

TableRowEvent.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    guests: PropTypes.array,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  status: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
};

export default compose(withFirebase)(TableRowEvent);
