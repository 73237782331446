import DateFnsUtils from '@date-io/date-fns';
import frLocale from 'date-fns/locale/fr';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Button, Container, LinearProgress } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import compareDesc from 'date-fns/compareDesc';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import { withFirebase } from '../../components/Firebase';
import { AuthUserContext, withAuthorization } from '../../components/Session';
import IOSSwitch from '../../components/IOSSwitch';
import nameRegExp from '../../utils/regexp';

const useStyles = makeStyles((theme) => ({
  FormControlLabel: {
    fontSize: 16,
    left: theme.spacing(0),
  },
  root: {
    marginLeft: theme.spacing(0),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function Transition(props) {
  return <Slide {...props} direction="up" />;
}

function UpdateForm({
  state, firebase, history, match, allCenter,
}) {
  const { data } = state;
  const [open, setOpen] = useState(false);
  const [transition, setTransition] = useState();
  const classes = useStyles();

  const handleShowSuccessMsg = () => {
    setTransition(() => Transition);
    setOpen(true);
  };

  const handleHideSuccessMsg = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    history.push('/events');
  };
  const [startDateIsOpen, setStartDateIsOpen] = useState(false);
  const [endDateIsOpen, setEndDateIsOpen] = useState(false);

  const eventId = match.params.id;
  const EventRef = firebase.db.collection('events');

  const modifSubmit = async (value) => {
    await EventRef.doc(eventId).set({
      campagneCode: value.campagneCode,
      name: value.name,
      startDate: value.startDate,
      endDate: value.endDate,
      porscheCenters: value.porscheCenters,
      activateSignature: value.activateSignature,
    }, { merge: true });
    await handleShowSuccessMsg();
  };
  const validateValues = (values) => {
    const errors = {};
    const result = compareDesc(new Date(values.startDate), new Date(values.endDate));

    if (result < 0) {
      errors.date = 'La date de fin doit être supérieure à la date de début';
    }
    if (!values.name) {
      errors.name = 'Veuillez renseigner un nom';
    } else if (values.name.length <= 2) {
      errors.name = 'Veuillez saisir un nom plus long (min. 2 caractères)';
    } else if (values.name.length > 100) {
      errors.name = 'Veuillez saisir un nom plus court (max. 100 caractères).';
    } else if (!values.name.match(nameRegExp)) {
      errors.name = 'Veuillez saisir un nom valide';
    }
    if (values.campagneCode && values.campagneCode.length > 24) {
      errors.campagneCode = 'Veuillez saisir un code campagne plus petit';
    }
    if (!values.porscheCenters.length) {
      errors.porscheCenters = 'Veuillez choisir au moins un centre Porsche !';
    }
    return errors;
  };
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  return (
    <Formik
      initialValues={{
        campagneCode: data.campagneCode,
        startDate: new Date(data.startDate.seconds * 1000),
        endDate: new Date(data.endDate.seconds * 1000),
        name: data.name,
        porscheCenters: data.porscheCenters,
        activateSignature: data.activateSignature || false,
      }}
      validate={validateValues}
      onSubmit={({
        campagneCode, startDate, endDate, name, porscheCenters, activateSignature,
      }, { setSubmitting }) => modifSubmit({
        campagneCode, startDate, endDate, name, porscheCenters, activateSignature,
      }, { setSubmitting })}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        setFieldValue,
        touched,
      }) => (
        <Container component="main" maxWidth="xs">
          <div>
            <br />
            <Typography component="h1" variant="h5">
              Mettre à jour un événement
            </Typography>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(values);
              }}
            >
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="name"
                id="name"
                autoComplete="name"
                value={values.name}
                label="name"
                onChange={handleChange}
                helperText={
                  touched.name
                    ? errors.name
                    : ''
                }
                error={
                  touched.name
                  && Boolean(errors.name)
                }
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="campagneCode"
                name="campagneCode"
                autoComplete="code campagne"
                autoFocus
                onChange={handleChange}
                value={values.campagneCode}
                label="Code campagne"
                helperText={
                  touched.campagneCode
                    ? errors.campagneCode
                    : ''
                }
                error={
                  touched.campagneCode
                  && Boolean(errors.campagneCode)
                }
              />
              <br />
              <br />
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                <KeyboardDatePicker
                  variant="inline"
                  open={startDateIsOpen}
                  onOpen={() => setStartDateIsOpen(true)}
                  onClose={() => setStartDateIsOpen(false)}
                  id="startDate"
                  label="Date de début"
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  margin="normal"
                  value={values.startDate}
                  onChange={(value) => { setFieldValue('startDate', value); setStartDateIsOpen(false); }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
                <br />
                <br />
                <KeyboardDatePicker
                  variant="inline"
                  open={endDateIsOpen}
                  onOpen={() => setEndDateIsOpen(true)}
                  onClose={() => setEndDateIsOpen(false)}
                  id="endDate"
                  inputVariant="outlined"
                  label="Date de fin"
                  format="dd/MM/yyyy"
                  margin="normal"
                  value={values.endDate}
                  onChange={(value) => { setFieldValue('endDate', value); setEndDateIsOpen(false); }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
              <br />
              <br />
              {errors.date && (
                <div style={{ color: 'red' }}>
                  {' '}
                  {errors.date}
                </div>
              )}
              <br />
              <br />
              <Autocomplete
                multiple
                id="size-small-filled-multi"
                size="medium"
                onChange={(_, value) => setFieldValue('porscheCenters', value)}
                value={values.porscheCenters}
                options={allCenter}
                disableCloseOnSelect
                renderOption={(center, { selected }) => (
                  <>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {center}
                  </>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Centre(s) Porsche"
                    placeholder="Sélectionner un centre Porsche !"
                    style={{ width: 500 }}
                    fullWidth
                    helperText={
                      touched.porscheCenters
                        ? errors.porscheCenters
                        : ''
                    }
                    error={
                      touched.porscheCenters
                        && Boolean(errors.porscheCenters)
                    }
                  />
                )}
              />
              <div />
              <FormControlLabel
                value="start"
                control={(
                  <IOSSwitch
                    color="primary"
                    checked={values.activateSignature}
                    onChange={(event) => setFieldValue('activateSignature', event.target.checked)}
                    name="activateSignature"
                  />
                )}
                label={(
                  <span className={classes.FormControlLabel}>
                    Essais (signature d'une décharge de responsabilité) :
                  </span>
                )}
                className={classes.root}
                labelPlacement="start"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Enregistrer les modifications
              </Button>

              <Snackbar
                TransitionComponent={transition}
                open={open}
                autoHideDuration={2000}
                onClose={handleHideSuccessMsg}
              >
                <Alert
                  elevation={2}
                  severity="success"
                  onClose={handleHideSuccessMsg}
                >
                  <AlertTitle> Succès </AlertTitle>
                  Les modifications ont bien été prises en compte.
                </Alert>
              </Snackbar>
            </form>
          </div>
        </Container>
      )}
    </Formik>
  );
}

function UpdateEvents(props) {
  const eventId = props.match.params.id;
  const [state, setState] = useState({
    data: null,
    porscheCenters: [],
    allCenter: [],
  });
  const [allCenter, setAllCenter] = useState([]);
  const authUser = useContext(AuthUserContext);
  useEffect(() => {
    let unsubscribe;

    if (authUser && authUser.role === 'ADMIN') {
      unsubscribe = props.firebase
        .porscheCenters()
        .onSnapshot((snapshot) => {
          const porscheCenters = [];

          snapshot.forEach((doc) => {
            const docData = { ...doc.data() };
            porscheCenters.push(docData.porscheCenterName);
          });
          setAllCenter(porscheCenters);
        });
    } else {
      unsubscribe = null;
      const porscheCenters = authUser && authUser.porscheCenters ? authUser.porscheCenters : [];
      setAllCenter(porscheCenters);
      setState({
        ...state,
        porscheCenters,
        loading: false,
      });
    }
    return function cleanup() {
      if (unsubscribe) unsubscribe();
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    const unsubscribe = props.firebase.event(eventId).onSnapshot(async (snapshot) => {
      const data = snapshot.data();
      if (data) {
        setState({
          ...state,
          data,
        });
      }
    });
    return function clear() {
      if (unsubscribe) unsubscribe();
    };
  }, []); // eslint-disable-line

  return (state.data && allCenter)
    ? <UpdateForm allCenter={allCenter} state={state} setState={setState} firebase={props.firebase} history={props.history} match={props.match} />
    : (
      <div>
        <div
          style={{ margin: '20px 0px' }}
        >
          <LinearProgress />
        </div>
        <div>
          <LinearProgress color="secondary" onClose={() => { setState({ ...state, loading: false }); }} />
        </div>
      </div>
    );
}

const condition = (authUser) => !!authUser;

const UpdateEventsForm = compose(
  withRouter,
  withFirebase,
  withAuthorization(condition),
)(UpdateEvents);

UpdateEvents.propTypes = {
  firebase: PropTypes.shape({
    db: PropTypes.object,
    events: PropTypes.func,
    porscheCenters: PropTypes.func,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default UpdateEventsForm;
