import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.secondaryApp = app.initializeApp(config, 'Secondary');
    /* Helper */

    this.timestamp = app.firestore.Timestamp;
    this.fieldValue = app.firestore.FieldValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */

    this.auth = app.auth();
    this.secondaryAuth = this.secondaryApp.auth();
    this.db = app.firestore();
    this.storage = app.storage();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) => this.secondaryAuth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password);

  doResetPassword = (email, options) => this.auth.sendPasswordResetEmail(email, options);

  doSignOut = () => this.auth.signOut();

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) => this.auth.onAuthStateChanged((authUser) => {
    if (authUser) {
      this.user(authUser.uid)
        .get()
        .then((snapshot) => {
          const dbUser = snapshot.data();

          // default empty roles
          if (dbUser && !dbUser.role) {
            dbUser.role = '';
          }

          authUser.getIdTokenResult()
            .then(({ token }) => {
              this.userToken = token;
            });

          // merge auth and db user
          const allUserData = {
            token: this.userToken,
            uid: authUser.uid,
            email: authUser.email,
            emailVerified: authUser.emailVerified,
            providerData: authUser.providerData,
            ...dbUser,
          };

          next(allUserData);
        });
    } else {
      fallback();
    }
  });

  // *** User API ***

  user = (uid) => this.db.doc(`users/${uid}`);

  users = () => this.db.collection('users');

  agents = () => this.db.collection('users').where('role', '==', 'AGENT').get();

  vendors = () => this.db.collection('users').where('role', '==', 'VENDEUR').get();

  // *** Events API ***
  events = () => this.db.collection('events');

  event = (uid) => this.db.doc(`events/${uid}`);

  // *** Porsche Centers API ***

  porscheCenter = (uid) => this.db.doc(`porsche-centers/${uid}`);

  porscheCenters = () => this.db.collection('porsche-centers');

  // *** Porsche Guests API ***

  guest = (uid) => this.db.doc(`guests/${uid}`);

  guests = () => this.db.collection('guests');

  // *** Porsche Prospects API ***

  prospect = (uid) => this.db.doc(`porsche-prospect/${uid}`);

  prospects = () => this.db.collection('porsche-prospect');
}

export default Firebase;
