import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Formik } from 'formik';
import { LinearProgress } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { withFirebase } from '../../components/Firebase';
import { withAuthorization } from '../../components/Session';
import ModelForm from '../../components/ModelForm';
import calculOrderModel from '../../helpers/calculOrderModel';

function EditModelForm({ firebase, history }) {
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    const { state } = history.location;
    setInitialValues(state);
  }, [initialValues, history.location]);
  const updateDocument = async (data) => {
    await firebase.db.collection('porsche-models').doc(initialValues.key).set({
      ...data,
    }, { merge: true })
      .then(() => {
        setIsLoading(false);
        history.push({
          pathname: '/catalog',
          state: {
            isModel: true,
          },
        });
      })
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    const order = await calculOrderModel(firebase, values.gammeId);
    updateDocument({ name: values.modelName, gamme_id: values.gammeId, order });
  };

  const validateValues = (values) => {
    const errors = {};
    if (!values.modelName.length || values.modelName?.trim().length === 0) {
      errors.modelName = 'Veuillez renseigner un nom du modèle';
    }
    if (!values.gammeId.length || values.gammeId?.trim().length === 0) {
      errors.gammeId = 'Veuillez selectionner une gamme';
    }
    return errors;
  };

  if (!initialValues) return <LinearProgress variant="query" color="secondary" />;

  return (
    <Formik
      validateOnChange={false}
      initialValues={{
        modelName: initialValues.modelName,
        gammeId: initialValues.gammeId,
      }}
      validate={validateValues}
      onSubmit={({
        modelName, gammeId,
      }, { setSubmitting }) => handleSubmit({
        modelName, gammeId,
      }, { setSubmitting })}
    >
      {(formik) => (
        <Container component="main">
          <ModelForm
            formik={formik}
            isLoading={isLoading}
            firebase={firebase}
            handleSubmit={handleSubmit}
            mutation="EDIT"
            initialValues={initialValues}
          />
        </Container>
      )}
    </Formik>
  );
}

const condition = (authUser) => !!authUser;

const EditModelFormContainer = compose(
  withRouter,
  withFirebase,
  withAuthorization(condition),
)(EditModelForm);

EditModelForm.propTypes = {
  firebase: PropTypes.shape({
    db: PropTypes.object,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default EditModelFormContainer;
