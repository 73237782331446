import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import React, {useContext, useRef } from 'react';
import IdleTimer from 'react-idle-timer';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../../components/Firebase';
import { AuthUserContext } from '../../components/Session';
import { MAIN } from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import theme from '../theme';
import Drawer from './Drawer';
import Footer from './Footer';
import Header from './Header';

const useStyles = makeStyles({
  content: {
    flexGrow: 1,
    paddingLeft: 250,
    minHeight: 800,
  },
  contentLO: {
    minHeight: 800,
  },
});

function Layout({
  children, firebase, history, location,
}) {
  const classes = useStyles();
  const authUser = useContext(AuthUserContext);
  const timeout = 1000 * 60 * 90;
  const idleTimer = useRef();
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onAction = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onActive = () => {};
  const onIdle = () => {
    if (authUser) {
      firebase.doSignOut();
      history.push(MAIN);
    }
  };
  return (
    <div>
      <IdleTimer
        ref={idleTimer}
        element={document}
        onActive={onActive}
        onIdle={onIdle}
        onAction={onAction}
        debounce={250}
        timeout={timeout}
      />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Header />
        {authUser && authUser.role !== ROLES.VENDOR ? (
          <>
            <Drawer location={location} />
            <main className={classes.content}>{children}</main>
          </>
        ) : (
          <main className={classes.contentLO}>{children}</main>
        )}
        <Footer />
      </ThemeProvider>
    </div>
  );
}
export default compose(withRouter, withFirebase)(Layout);
