/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { FirebaseContext } from '../../components/Firebase';
import { AuthUserContext } from '../../components/Session';

import { formatBytes, formatDate, formatHour } from '../../utils/helper';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 16,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  buttons: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  cellhead: {
    fontSize: '18px',
    position: 'sticky',
    top: 0,
  },
  title: {
    margin: '15px 5px',
  },
  tableContainer: {
    maxHeight: '750px',
  },
}));

const initialStateAlert = {
  typeAlert: null,
  message: '',
};
function Persons() {
  const [imports, setImports] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dataAlert, setDataAlert] = useState(initialStateAlert);

  const authUser = useContext(AuthUserContext);
  const {
    db, auth, timestamp, storage,
  } = useContext(FirebaseContext);

  useEffect(() => {
    const subscriber = db
      .collection('imports')
      .where('status', '==', 'FINISH')
      .orderBy('timestamp', 'desc')
      .onSnapshot(
        (snap) => {
          if (!snap.empty) {
            const data = snap.docs.map((elem) => ({
              id: elem.id,
              ...elem.data(),
            }));
            setImports(data);
          }
          setLoading(false);
        },
        (error) => {
          console.error('error on real time update of imports collection -onSnapshot- :', error);
          setLoading(false);
        },
      );

    return () => {
      subscriber();
    };
  }, [db]);

  const classes = useStyles();

  const handleFile = async (event) => {
    try {
      event.persist();

      setUploading(true);

      const file = event.target.files[0];
      // Vérification du type de fichier
      if (file.type !== 'text/csv') {
        setDataAlert({ type: 'error', message: 'Veuillez sélectionner un fichier CSV.', duration: 6000 });
        return;
      }

      const idFile = Date.now();

      /*  /**
       * BYPASS : désactivation du test d'import en cours en base pour ne pas bloquer l'utilisateur
       */
      // const querySnap = await db
      //   .collection('imports')
      //   .where('status', '==', 'PENDING')
      //   .get();

      // if (!querySnap.empty) {
      //   setDataAlert({ type: 'warning', message: 'Import déjà en cours !', duration: 6000 });
      //   return;
      // } */

      setDataAlert({
        type: 'info',
        message: 'Veuillez patienter pendant le chargement du fichier avant de quitter.',
        duration: null,
      });
      const reader = new FileReader();
      reader.onload = async (e) => {
        const fileContent = e.target.result;
        const fileContentWithoutQuotes = fileContent.replace(/"/g, ''); // Supprime les caractères spéciaux
        fileContentWithoutQuotes.replace(/[^\w\s.,;:'"()-]/g, '');
        const modifiedBlob = new Blob([fileContentWithoutQuotes], { type: 'text/csv' });
        const fileRef = storage.ref(`crm/${idFile}.csv`);
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        fileRef.put(modifiedBlob).then(() => {
        }).catch(() => {
          setDataAlert({ type: 'error', message: "Echec de l'import", duration: 6000 });
        });
        setDataAlert(initialStateAlert);
        setLoading(true);
        const token = await auth.currentUser.getIdToken(true);

        const infoImport = {
          fileName: file.name.replace('.csv', '').trim(),
          fileSize: formatBytes(file.size),
          userName: authUser.username,
          idUser: authUser.uid,
          hour: formatHour(timestamp.fromDate(new Date())),
          date: formatDate(timestamp.fromDate(new Date())),
          timestamp: idFile,
        };

        let response;

        try {
          response = await axios.post(
            `${process.env.REACT_APP_IMPORT_EXPORT_URL}/processExcel`,
            { idFile, infoImport },
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          );
        } catch (error) {
          console.error("Erreur lors de l'appel à axios.post :", error);
          setDataAlert({ type: 'error', message: "Erreur lors de l'import", duration: 6000 });
        }
        if (response.status === 200) {
          setDataAlert({ type: 'success', message: 'Import réalisé avec succès', duration: 6000 });
        } else {
          setDataAlert({ type: 'error', message: "Echec de l'import", duration: 6000 });
        }
      };
      reader.readAsText(file);
    } catch (error) {
      setDataAlert({ type: 'error', message: "Echec de l'import", duration: 6000 });
    } finally {
      setUploading(false);
      setLoading(false);
    }
  };

  return loading ? (
    <div
      style={{
        textAlign: 'center',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress color="secondary" />
    </div>
  ) : (
    <div>
      <Typography component="h2" variant="h6" color="primary" className={classes.title}>
        Liste des Personnes
      </Typography>
      <br />
      <br />
      <div style={{ textAlign: 'right', margin: '10px', marginBottom: '30px' }}>
        <label
          htmlFor="crm"
          style={{
            backgroundColor: '#d5001c',
            cursor: 'pointer',
            padding: '0.5rem',
            color: 'white',
            marginBottom: '20px',
            fontSize: '0.95rem',
            boxSizing: 'border-box',
            fontFamily: 'PorscheNextTT, sans-serif',
            fontWeight: 700,
            borderRadius: '4px',
            boxShadow:
              '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
            pointerEvents: uploading ? 'none' : 'inherit',
            opacity: uploading ? '70%' : '100',
          }}
        >
          {uploading ? 'Import CRM en cours ...' : 'Import CRM'}
        </label>
        <input type="file" id="crm" accept=".csv" hidden onChange={(event) => handleFile(event)} />
      </div>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow className={classes.cellhead}>
              <StyledTableCell className={classes.cellhead} align="center">
                Date
              </StyledTableCell>
              <StyledTableCell className={classes.cellhead} align="center">
                Heure
              </StyledTableCell>
              <StyledTableCell className={classes.cellhead} align="center">
                Nom du fichier
              </StyledTableCell>
              <StyledTableCell className={classes.cellhead} align="center">
                Taille du fichier
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {imports.map(({
              date, hour, fileName, fileSize,
            }, index) => (
              <StyledTableRow className={classes.cellhead} key={index}>
                <StyledTableCell align="center">
                  {date}
                  {' '}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {' '}
                  {hour}
                  {' '}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {' '}
                  {fileName}
                  {' '}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {' '}
                  {fileSize}
                  {' '}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Snackbar
        open={Boolean(dataAlert.type)}
        autoHideDuration={dataAlert.duration}
        onClose={() => setDataAlert(initialStateAlert)}
      >
        <Alert onClose={() => setDataAlert(initialStateAlert)} severity={dataAlert.type}>
          {dataAlert.message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Persons;
