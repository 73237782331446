import {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { AuthUserContext } from '../../../components/Session';

export default function useEventList(firebase) {
  const [events, setEvents] = useState([]);
  const authUser = useContext(AuthUserContext);

  const asyncFetch = useCallback(async () => {
    const eventsRef = firebase.db.collection('events');
    const eventList = authUser.role === 'ADMIN'
      ? await eventsRef.orderBy('startDate', 'asc').get()
      : await eventsRef
        .where('porscheCenters', 'array-contains-any', authUser.porscheCenters)
        .orderBy('startDate', 'asc')
        .get();

    const today = Date.now();
    const eventslist = eventList.docs
      .map((event) => ({ eventID: event.id, ...event.data() }))
      .filter((event) => event.startDate.seconds * 1000 <= today);
    setEvents(eventslist);
  }, []); // eslint-disable-line

  useEffect(() => {
    asyncFetch();
  }, []); // eslint-disable-line
  return events;
}
