import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Button from '@material-ui/core/Button';
import {
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  Fab,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from '@material-ui/lab';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../../components/Firebase';
import { withAuthorization } from '../../components/Session';
import assets from '../../assets';
import { GammeFilters } from '../../components/CatalogFilters';
import { SearchInput } from '../../shared/Layout/SearchInput';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 16,
  },
}))(TableCell);

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    margin: '30px 20px 0px 30px',
  },
  table: {
    minWidth: 700,
  },
  cellhead: {
    fontSize: '16px',
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '23px',
    '& th:nth-child(1)': {
      width: '100px',
    },
    '& th:nth-child(2)': {
      width: '200px',
    },
    '& th:nth-child(3)': {
      width: '200px',
    },
    '& th:nth-child(4)': {
      width: '200px',
    },
  },
  Fab: {
    width: '35px',
    height: '30px',
    marginLeft: '15px',
    marginTop: '5px',
  },
  submitButton: {
    fontSize: '16px',
    letterSpacing: '0',
    lineHeight: '23px',
    float: 'right',
    marginBottom: '20px',
    textTransform: 'none',
    marginTop: '10px',
  },
  modal: {
    maxWidth: '536px',
    margin: '0 auto',
  },
  modalTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '36px',
    textAlign: 'center',
    textTransform: 'unset',
  },
  modalContent: {
    fontSize: '16px',
    letterSpacing: 0,
    lineHeight: '23px',
    padding: '0 20px',
  },
  modalAction: {
    padding: '8px 40px',
    justifyContent: 'space-between',
    marginBottom: '40px',
  },
  modalCancelBtn: {
    backgroundColor: '#313639',
    fontSize: '16px',
    letterSpacing: '0',
    lineHeight: '23px',
    textTransform: 'unset',
    color: 'white',
    padding: '10px 20px',
    '&:hover': {
      backgroundColor: '#313639',
      color: 'white',
    },
  },
  modalSubmitBtn: {
    backgroundColor: '#D5001C',
    fontSize: '16px',
    letterSpacing: '0',
    lineHeight: '23px',
    textTransform: 'unset',
    color: 'white',
    padding: '10px 20px',
    position: 'relative',
    '&:hover': {
      backgroundColor: '#D5001C',
      color: 'white',
    },
  },
  iconClose: {
    position: 'absolute',
    right: '20px',
    top: '17px',
  },
  msgText: {
    paddingBottom: '10px',
    color: 'red',
  },
}));

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

function ModelsListPage({ firebase, history }) {
  const [models, setModels] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const [selectedModel, setSelectedModel] = React.useState(null);
  const [displayMsg, setDisplayMsg] = React.useState(false);
  const [dragDrop, setDragDrop] = React.useState(false);
  const [alert, setAlert] = React.useState({
    isOpen: false,
    msg: '',
  });
  const [gammesID, setGammesId] = useState([]);
  const [searchedModelName, setSearchedModelName] = useState('');
  const DELETE_ERROR_MSG = 'Désolé, ce modèle n’a pas pu être supprimée';
  const classes = useStyles();

  const getModels = React.useCallback(async () => {
    const gammesSnapshot = await firebase.db.collection('porsche-gammes').orderBy('order');

    const modelsSnapshot = await firebase.db.collection('porsche-models').orderBy('order');

    const gammes = await gammesSnapshot.get();
    const modelsList = [];
    const promises = gammes.docs.map(async (doc) => {
      const gammeId = doc.id;
      const dataGamme = doc.data();

      const model = await modelsSnapshot.where('gamme_id', '==', gammeId).get();
      model.docs.map(async (d) => {
        const data = await d.data();
        modelsList.push({
          name: data.name,
          key: d.id,
          nameGamme: dataGamme.name,
          idGamme: gammeId,
          order: data.order,
        });
      });
    });
    await Promise.all(promises);

    setModels(modelsList);
  }, [firebase.db]);

  useEffect(() => {
    getModels();
  }, [getModels]);

  function handleAdd() {
    history.push('add-model');
  }

  async function handleOnDragEnd(result) {
    setDragDrop(true);
    if (!result.destination) return;
    const items = Array.from(models);
    const source = result.source.index;
    const destination = result.destination.index;
    const itemSource = items.find((item) => item.order === source);
    const itemDestination = items.find((item) => item.order === destination);
    const selectedGamme = gammesID.length === 1;
    if (selectedGamme && result.destination && result.source) {
      setDisplayMsg(false);
    } else {
      setDisplayMsg(true);
      return;
    }
    setModels(items);
    const batch = firebase.db.batch();
    const docRefSource = firebase.db.collection('porsche-models').doc(itemSource.key);
    batch.update(docRefSource, { order: itemDestination.order });
    const docRefDest = firebase.db.collection('porsche-models').doc(itemDestination.key);
    batch.update(docRefDest, { order: itemSource.order });
    batch
      .commit()
      .then(() => {
        getModels();
      })
      .catch(() => setAlert({
        isOpen: true,
        msg: "Le changement de position n'a pas pu être enregistré",
      }));
  }

  function handleEdit(model) {
    history.push({
      pathname: '/update-model',
      state: {
        key: model?.key,
        modelName: model?.name,
        gammeName: model?.nameGamme,
        gammeId: model?.idGamme,
      },
    });
  }

  async function deleteVersions({ modelId, callBack }) {
    try {
      firebase.db
        .collection('porsche-versions')
        .where('model_id', '==', modelId)
        .get()
        .then((resp) => {
          const batchDeleteVersions = firebase.db.batch();
          resp.docs.forEach((doc) => {
            const docRef = firebase.db.collection('porsche-versions').doc(doc.id);
            batchDeleteVersions.delete(docRef);
            if (doc.data().url) {
              const storageRef = firebase.storage.ref(`versions/${doc.id}`);
              storageRef.delete();
            }
          });
          batchDeleteVersions
            .commit()
            .then(() => {
              callBack();
            })
            .catch(() => setAlert({
              isOpen: true,
              msg: DELETE_ERROR_MSG,
            }));
        });
    } catch {
      setAlert({
        isOpen: true,
        msg: DELETE_ERROR_MSG,
      });
    }
  }

  async function handleDelete() {
    try {
      const modelRef = firebase.db.collection('porsche-models');
      modelRef
        .doc(selectedModel?.key)
        .delete()
        .then(() => {
          deleteVersions({
            modelId: selectedModel?.key,
            callBack: () => {
              getModels();
              handleCloseModal();
            },
          });
        })
        .catch(() => setAlert({
          isOpen: true,
          msg: DELETE_ERROR_MSG,
        }));
    } catch (err) {
      handleCloseModal();
      setAlert({
        isOpen: true,
        msg: DELETE_ERROR_MSG,
      });
    }
  }

  const handleOpenModal = (model) => {
    setSelectedModel(model);
    setIsDeleteModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsDeleteModalOpen(false);
  };

  function handleCloseAlert() {
    setAlert({
      isOpen: false,
      msg: '',
    });
  }

  function filterByGamme(model) {
    return gammesID.length > 0 ? gammesID.includes(model.idGamme) : true;
  }

  function filterByModelName(model) {
    return searchedModelName.length > 0
      ? model.name.toLowerCase().indexOf(searchedModelName) > -1
      : true;
  }
  const selectedNumber = gammesID.length === 1;
  return (
    <div>
      <Button
        type="button"
        variant="contained"
        color="secondary"
        className={classes.submitButton}
        onClick={() => handleAdd()}
      >
        Ajouter un modèle
      </Button>
      {alert.isOpen && (
        <Alert
          severity="error"
          style={{
            width: '50%', position: 'absolute', top: 20, right: 20,
          }}
          onClose={() => handleCloseAlert()}
        >
          {alert.msg}
        </Alert>
      )}

      <SearchInput
        setSearchFunction={setSearchedModelName}
        label="Rechercher un modèle"
        maxLength={50}
      />
      <GammeFilters setGammesId={setGammesId} gammesID={gammesID} />
      {displayMsg && !selectedNumber && dragDrop && (
        <div className={classes.msgText}>
          Veuillez sélectionner une seule gamme pour changer l'ordre des modèles.
        </div>
      )}
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow className={classes.cellhead}>
              <StyledTableCell className={classes.cellhead} align="center">
                Position
              </StyledTableCell>
              <StyledTableCell className={classes.cellhead} align="center">
                Nom
              </StyledTableCell>
              <StyledTableCell className={classes.cellhead} align="center">
                Gamme
              </StyledTableCell>
              <StyledTableCell className={classes.cellhead} align="center">
                Actions
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={() => handleOnDragEnd()}>
            <Droppable droppableId="models">
              {(provided) => (
                <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                  {models
                    && models
                      .filter(filterByGamme)
                      .filter(filterByModelName)
                      .map((model) => (
                        <Draggable key={model.key} draggableId={model.key} index={model.order}>
                          {(provid) => (
                            <StyledTableRow
                              ref={provid.innerRef}
                              {...provid.draggableProps}
                              {...provid.dragHandleProps}
                            >
                              <StyledTableCell align="center">
                                <img alt="icon-drag-drop" src={assets.dragDropIcon} />
                              </StyledTableCell>
                              <StyledTableCell align="center">{model.name}</StyledTableCell>
                              <StyledTableCell align="center">{model.nameGamme}</StyledTableCell>
                              <StyledTableCell align="center">
                                <div>
                                  <Fab className={classes.Fab} color="primary">
                                    <DeleteIcon onClick={() => handleOpenModal(model)} />
                                  </Fab>
                                  <Fab
                                    className={classes.Fab}
                                    color="default"
                                    onClick={() => handleEdit(model)}
                                  >
                                    <EditIcon />
                                  </Fab>
                                </div>
                              </StyledTableCell>
                            </StyledTableRow>
                          )}
                        </Draggable>
                      ))}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
        <Dialog
          open={isDeleteModalOpen && !!selectedModel}
          onClose={() => handleCloseModal()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.modal}
        >
          <IconButton className={classes.iconClose} aria-label="close" onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
          <p className={classes.modalTitle} id="alert-dialog-title">
            Confirmation de suppression
          </p>
          <DialogContent>
            <p className={classes.modalContent} id="alert-dialog-description">
              Souhaitez-vous vraiment supprimer le modèle
              {' '}
              {selectedModel?.name}
              . Attention, la
              suppression de ce modèle entrainera la suppression de toutes les versions associées.
            </p>
          </DialogContent>
          <DialogActions className={classes.modalAction}>
            <Button onClick={handleCloseModal} className={classes.modalCancelBtn}>
              Annuler
            </Button>
            <Button onClick={() => handleDelete()} className={classes.modalSubmitBtn}>
              &gt;&nbsp; Supprimer
            </Button>
          </DialogActions>
        </Dialog>
      </TableContainer>
    </div>
  );
}

const condition = (authUser) => !!authUser;
export default compose(withFirebase, withAuthorization(condition), withRouter)(ModelsListPage);
