import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Formik } from 'formik';
import Container from '@material-ui/core/Container';
import { withFirebase } from '../../components/Firebase';
import { withAuthorization } from '../../components/Session';
import GammeForm from '../../components/GammeForm';

function AddGammeForm({ firebase, history }) {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values) => {
    setIsLoading(true);

    const orderData = await firebase.db
      .collection('porsche-gammes')
      .orderBy('order', 'desc')
      .limit(1)
      .get();

    const lastNumber = orderData.docs.length !== 0 && orderData.docs[0].exists
      ? orderData.docs[0].data().order
      : 0;

    const order = Number(lastNumber) + 1;

    await firebase.db
      .collection('porsche-gammes')
      .add({ name: values.gammeName, order })
      .then(async (docRef) => {
        const path = 'gammes/';
        const storageRef = firebase.storage.ref(`${path}/${docRef.id}`);
        const blob = await fetch(values.fileUrl).then((r) => r.blob());
        storageRef.put(blob)
          .then(async (snapshot) => {
            const url = await snapshot.ref.getDownloadURL();
            firebase.db.collection('porsche-gammes')
              .doc(docRef.id)
              .update({ url })
              .then(() => {
                setIsLoading(false);
                history.push({
                  pathname: '/catalog',
                  state: {
                    isGamme: true,
                  },
                });
              }) // eslint-disable-next-line no-console
              .catch((e) => console.error('Error when linking', e));
          })// eslint-disable-next-line no-console
          .catch((e) => console.error('Error when uploading', e));
      })// eslint-disable-next-line no-console
      .catch((e) => console.error('Error when adding new gamme', e));
  };

  const validateValues = (values) => {
    const errors = {};
    if (!values.gammeName.length || values.gammeName?.trim().length === 0) {
      errors.gammeName = 'Veuillez renseigner un nom de gamme';
    }
    return errors;
  };
  return (
    <Formik
      validateOnChange={false}
      initialValues={{
        gammeName: '',
        fileUrl: '',
      }}
      validate={validateValues}
      onSubmit={({ gammeName, fileUrl }, { setSubmitting }) => handleSubmit({ gammeName, fileUrl }, { setSubmitting })}
    >
      {(formik) => (
        <Container component="main">
          <GammeForm
            formik={formik}
            isLoading={isLoading}
            firebase={firebase}
            handleSubmit={handleSubmit}
            mutation="ADD"
          />
        </Container>
      )}
    </Formik>
  );
}

const condition = (authUser) => !!authUser;

const AddGammeFormContainer = compose(
  withRouter,
  withFirebase,
  withAuthorization(condition),
)(AddGammeForm);

AddGammeForm.propTypes = {
  firebase: PropTypes.shape({
    db: PropTypes.object,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default AddGammeFormContainer;
