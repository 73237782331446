const calculOrderVersion = async (firebase, modelId) => {
  const getOrderModel = await firebase.db.collection('porsche-models').doc(modelId).get();

  const orderModelData = getOrderModel.data();
  const orderGamme = orderModelData.order;
  const orderData = await firebase.db
    .collection('porsche-versions')
    .orderBy('order', 'desc')
    .where('model_id', '==', modelId)
    .limit(1)
    .get();
  const ORDER_STEP = 1000;
  const lastNumber = orderData.docs.length !== 0 && orderData.docs[0].exists ? orderData.docs[0].data().order : 0;
  const isFirst = Number(lastNumber) === 0;
  const order = isFirst
    ? Number(lastNumber) + 1
    : (Number(lastNumber) - (orderGamme * ORDER_STEP)) + 1;
  const orderCalc = (orderGamme * ORDER_STEP) + order;
  return orderCalc;
};
export default calculOrderVersion;
