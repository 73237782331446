import Button from '@material-ui/core/Button';
import React from 'react';
import { withFirebase } from '../Firebase';
import assets from '../../assets';

function SignOutButton({ firebase }) {
  return (
    <Button
      startIcon={(
        <img
          src={assets.redArrow}
          width="6"
          height="12"
          alt="red arrow"
        />
      )}
      color="default"
      style={{ textTransform: 'none', fontSize: '16px' }}
      onClick={firebase.doSignOut}
    >
      Déconnexion
    </Button>
  );
}

export default withFirebase(SignOutButton);
